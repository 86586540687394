import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'catalogo',
    loadChildren: () => import('./pages/catalogo/catalogo.module').then( m => m.CatalogoPageModule)
  },
  {
    path: 'chi-siamo',
    loadChildren: () => import('./pages/chi-siamo/chi-siamo.module').then( m => m.ChiSiamoPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'contattaci',
    loadChildren: () => import('./pages/contattaci/contattaci.module').then( m => m.ContattaciPageModule)
  },
  {
    path: 'prodotti',
    loadChildren: () => import('./pages/prodotti/prodotti.module').then( m => m.ProdottiPageModule)
  },
  {
    path: 'busta-minigrip-originale',
    loadChildren: () => import('./pages/busta-minigrip-originale/busta-minigrip-originale.module').then( m => m.BustaMinigripOriginalePageModule)
  },
  {
    path: 'busta-salvafreschezza',
    loadChildren: () => import('./pages/busta-salvafreschezza/busta-salvafreschezza.module').then( m => m.BustaSalvafreschezzaPageModule)
  },
  {
    path: 'buste-minigrip-mediche',
    loadChildren: () => import('./pages/buste-minigrip-mediche/buste-minigrip-mediche.module').then( m => m.BusteMinigripMedichePageModule)
  },
  {
    path: 'buste-biocompostabili',
    loadChildren: () => import('./pages/buste-biocompostabili/buste-biocompostabili.module').then( m => m.BusteBiocompostabiliPageModule)
  },
  {
    path: 'busta-antistatica-anticorrosiva',
    loadChildren: () => import('./pages/busta-antistatica-anticorrosiva/busta-antistatica-anticorrosiva.module').then( m => m.BustaAntistaticaAnticorrosivaPageModule)
  },
  {
    path: 'busta-con-chiusura-adesiva',
    loadChildren: () => import('./pages/busta-con-chiusura-adesiva/busta-con-chiusura-adesiva.module').then( m => m.BustaConChiusuraAdesivaPageModule)
  },
  {
    path: 'busta-con-cursore',
    loadChildren: () => import('./pages/busta-con-cursore/busta-con-cursore.module').then( m => m.BustaConCursorePageModule)
  },
  {
    path: 'sicurezza-contenitori-valuta',
    loadChildren: () => import('./pages/sicurezza-contenitori-valuta/sicurezza-contenitori-valuta.module').then( m => m.SicurezzaContenitoriValutaPageModule)
  },
  {
    path: 'imballaggio-spedizione',
    loadChildren: () => import('./pages/imballaggio-spedizione/imballaggio-spedizione.module').then( m => m.ImballaggioSpedizionePageModule)
  },
  {
    path: 'sistemi-apri-chiudi',
    loadChildren: () => import('./pages/sistemi-apri-chiudi/sistemi-apri-chiudi.module').then( m => m.SistemiApriChiudiPageModule)
  },
  {
    path: 'confezionatrice-simplicita',
    loadChildren: () => import('./pages/confezionatrice-simplicita/confezionatrice-simplicita.module').then( m => m.ConfezionatriceSimplicitaPageModule)
  },
  {
    path: 'buste-standup-doypack',
    loadChildren: () => import('./pages/buste-standup-doypack/buste-standup-doypack.module').then( m => m.BusteStandupDoypackPageModule)
  },
  {
    path: 'ganci-grip',
    loadChildren: () => import('./pages/ganci-grip/ganci-grip.module').then( m => m.GanciGripPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
